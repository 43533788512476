<template>
    <div>
      <ChatToggle :nameInitials="nameInitials" :companyName="companyName" @toggle="toggleChatBox"/>
      <ChatBox v-show="showChatBox" :show="showChatBox" @close="toggleChatBox" />
    </div>
  </template>
  
  <script> 
  import ChatToggle from "./ChatToggle.vue";
  import ChatBox from "./ChatBox.vue";
  import companyService from "@/services/companyService";
  import { mapGetters } from "vuex";

  export default {
    name: "ChatContainer",
    components: { ChatToggle, ChatBox },
    data() {
      return {
        showChatBox: false,
        nameInitials: "",
        companyName: "",
        hasMessagingRole: false,
        messagingFlagEnabled: false,
        loading: true
      };
    },
    methods: {
        async init() {
            const companyId = this.selectedCompanyId; 
            
            if (companyId) {
                this.companyName = await companyService.getName(companyId);

                this.nameInitials = this.companyName
                    .match(/\b(\w)/g)
                    .join("")
                    .toUpperCase();
            }

            this.loading = false;
        },
        toggleChatBox() {
            this.showChatBox = !this.showChatBox;
        },
    },
    mounted() {
        this.init();
    },
    computed: {
        ...mapGetters("auth0Store", ["selectedCompanyId"]),
        ...mapGetters("chatStore", ["channels"])//Listens to the channel update event
    },
  };
  </script>
  