<template>
  <v-card class="pa-2 d-flex align-center justify-space-between">
    <div class="d-flex align-center">
      <v-avatar color="primary" size="40">
        <span class="white--text">{{ nameInitials }}</span>
      </v-avatar>
      <span class="ml-2 font-weight-bold">{{ companyName }}</span>
    </div>
    <div class="d-flex align-center">
      <v-btn icon @click="toggleChat" color="primary">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-btn icon>
        <v-icon>mdi-dots-horizontal</v-icon>
      </v-btn>
      <v-btn icon>
        <v-icon>mdi-window-minimize</v-icon>
      </v-btn>
      <v-btn icon>
        <v-icon>mdi-window-maximize</v-icon>
      </v-btn>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "ChatToggle",
  props: {
    nameInitials: {
      type: String,
      default: "",
    },
    companyName: {
      type: String,
      default: "",
    },
  },
  methods: {
    toggleChat() {
      this.$emit("toggle");
    },
  },
};
</script>
