import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Document from "../views/Document.vue";
import UserList from "../views/admin/UserList.vue";
import Register from "../views/Register.vue";
import RegisterSuccess from "../views/RegisterSuccess.vue";
//import Settings from "../views/admin/Settings.vue";
import OrgUnitList from "../views/admin/OrgUnitList.vue";
import OrgUnit from "../views/admin/OrgUnit.vue";
import User from "../views/admin/User.vue";
import { authGuard } from "../auth";
import NotFound from "../views/NotFound.vue";
import LandingPage from "../views/LandingPage.vue";
import PartyList from "../views/admin/PartyList.vue";
import PartyListImport from "../views/admin/PartyListImport.vue";
import Party from "../views/admin/Party.vue";
import CompanyLogo from "../views/admin/CompanyLogo.vue";
import InvitationList from "../views/admin/InvitationList.vue";
import Invitation from "../views/admin/Invitation";
import AdditionalInvitation from "../views/admin/AdditionalInvitation.vue";
import RegisterInvitation from "../views/admin/RegisterInvitation.vue";
import OnBoarding from "../views/admin/OnBoarding.vue";
import LegalDocumentList from "../views/admin/LegalDocumentList";
import LegalDocument from "../views/admin/LegalDocument";
import Inbox from "../views/Inbox";
import DocumentList from "../views/DocumentList";
import Profile from "../views/Profile";
import SearchResult from "../views/SearchResult";
import ErrorLoading from "../views/ErrorLoading";
import Notifications from "../views/Notification";
import AchList from "../views/admin/AchList.vue";
import Ach from "../views/admin/Ach.vue";
import PakServiceCallbakHandler from "../views/PakServiceCallbackHanlder";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    beforeEnter: authGuard,
  },
  {
    path: "/documents/:id",
    name: "Document",
    component: Document,
    beforeEnter: authGuard,
  },
  {
    path: "/callbackPakservice",
    name: "callbakPakservice",
    component: PakServiceCallbakHandler,
    props: route => ({ companyId: Number(route.query.companyId) }),
  },
  {
    path: "/admin/users",
    name: "UserList",
    component: UserList,
    beforeEnter: authGuard,
  },
  {
    path: "/admin/user/:id",
    name: "User",
    component: User,
    beforeEnter: authGuard,
  },
  {
    path: "/admin/logo",
    name: "CompanyLogo",
    component: CompanyLogo,
  },
  {
    path: "/admin/invitations",
    name: "InvitationList",
    component: InvitationList,
    beforeEnter: authGuard,
    props: route => ({ idByNotification: route.params.idByNotification }),
  },
  {
    path: "/admin/invitation/:invitationId",
    name: "Invitation",
    component: Invitation,
    beforeEnter: authGuard,
    props: route => ({ invitationId: route.params.invitationId, invitation: route.params.invitation }),
  },
  {
    path: "/admin/additional-invitation/:invitationId",
    name: "AdditionalInvitation",
    component: AdditionalInvitation,
    props: route => ({ invitationId: route.params.invitationId, invitation: route.params.invitation }),
  },
  {
    path: "/admin/invitations/:invitationToken",
    name: "RegisterInvitation",
    component: RegisterInvitation,
    beforeEnter: authGuard,
  },
  {
    path: "/admin/onboarding/:invitationToken",
    name: "OnBoarding",
    component: OnBoarding,
    beforeEnter: authGuard,
    props: true,
  },
  {
    path: "/admin/LegalDocuments/:legalDocumentTypeId",
    name: "LegalDocuments",
    component: LegalDocumentList,
    props: route => ({ legalDocumentTypeId: Number(route.params.legalDocumentTypeId) }),
    beforeEnter: authGuard,
  },
  {
    path: "/admin/legalDocument/:legalDocumentTypeId/:id",
    name: "LegalDocument",
    component: LegalDocument,
    props: route => ({
      passedId: route.params.id.toString(),
      legalDocumentTypeId: Number(route.params.legalDocumentTypeId),
    }),
    beforeEnter: authGuard,
  },
  {
    path: "/admin/achs",
    name: "Achs",
    component: AchList,
    beforeEnter: authGuard,
  },
  {
    path: "/admin/ach/:id",
    name: "Ach",
    component: Ach,
    beforeEnter: authGuard,
  },
  {
    path: "/admin/orgunits",
    name: "OrgUnitList",
    component: OrgUnitList,
    beforeEnter: authGuard,
  },
  {
    path: "/admin/orgunit/:id",
    name: "OrgUnit",
    component: OrgUnit,
    beforeEnter: authGuard,
  },
  {
    path: "/admin/connections",
    name: "Connections",
    component: PartyList,
    props: () => ({ onlyPossibleConnections: true }),
    
    beforeEnter: authGuard,
  },
  {
    path: "/admin/parties",
    name: "Connections",
    component: PartyList,
    props: () => ({ onlyPossibleConnections: false }),
    beforeEnter: authGuard,
  },
  {
    path: "/admin/connection/import",
    name: "ConnectionListImport",
    component: PartyListImport,
    beforeEnter: authGuard,
  },
  {
    path: "/admin/connection/:id",
    name: "Connection",
    component: Party,
    beforeEnter: authGuard,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/register/:invitationToken",
    name: "RegisterByInvitation",
    component: Register,
    props: route => ({ invitationToken: route.params.invitationToken, isBoarding: false }),
  },
  {
    path: "/onboarding/:invitationToken",
    name: "BoardingCompany",
    component: Register,
    props: route => ({ invitationToken: route.params.invitationToken, isBoarding: true }),
  },
  {
    path: "/registersuccess",
    props: true,
    name: "RegisterSuccess",
    component: RegisterSuccess,
  },
  {
    path: "/landingpage",
    name: "LandingPage",
    component: LandingPage,
    props: route => ({ redirectTo: route.query.redirectTo }),
  },
  {
    path: "/login",
    name: "Login",
    redirect: "/landingpage",
  },
  {
    path: "/errorLoading",
    name: "ErrorLoading",
    component: ErrorLoading,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    beforeEnter: authGuard,
  },
  {
    path: "/profile/unsubscribe",
    name: "UnsubscribeEmail",
    component: Profile,
    beforeEnter: authGuard,
    props: { unsubscribeEmail: true },
  },
  {
    path: "/inbox",
    name: "Inbox",
    component: Inbox,
    beforeEnter: authGuard,
  },
  {
    path: "/documentlist/:documentTypeId",
    name: "DocumentList",
    component: DocumentList,
    props: route => ({
      documentTypeId: Number(route.params.documentTypeId),
      routeStatusId: Number(route.query.documentRouteStatusId || 0),
    }),
    beforeEnter: authGuard,
  },
  {
    path: "/search",
    name: "SearchResult",
    component: SearchResult,
    beforeEnter: authGuard,
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: Notifications,
    beforeEnter: authGuard,
  },
  {
    path: "*",
    component: NotFound,
    beforeEnter: authGuard,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // This is ugly, will try to find a better way (its to get the splitpanels to work) / Jonas
  global.document.getElementsByTagName("html")[0].style.overflow = "auto";
  next();
});

export default router;
