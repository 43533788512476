<template>
  <v-container fluid>
    <v-card-title class="pa-4" v-if="!hasNoConnections"> <v-icon left>mdi-home</v-icon> Home </v-card-title>
    <v-card-title class="pa-4" v-if="hasNoConnections"> <v-icon left>mdi-home</v-icon> Welcome to DocVue Exchange </v-card-title>
    <v-card-text v-if="hasNoConnections"> To get started, please review your third-party connections on the left under Connections and once connected you'll be able to see the documents available to you. </v-card-text>

    <v-row class="pa-0 ma-0">
      <v-col cols="12" md="4" class="pt-0">
        <div class="pl-4 pr-4" v-if="loaded && isVendor">
          <v-card-title class="pa-4">AR Invoices Sent</v-card-title>
          <LineChart :chartData="documentsSent"></LineChart>
        </div>
      </v-col>
      
      <!--
      <v-col cols="12" md="4">
        <div class="pl-4 pr-4" v-if="loaded">
          <v-card-title class="pa-4">Sent by Type</v-card-title>
          <PieChart :chartData="documentsByType.counts" :chartLabels="documentsByType.labels"></PieChart>
        </div>
      </v-col>
      -->
      <v-col cols="12" md="4" class="pt-0">
        <div class="pl-4 pr-4" v-if="loaded && isVendor">
          <v-card-title class="pa-4">Payment Status</v-card-title>
          <BarChart
            :onClick="paymentStatusClicked"
            :chartData="statusCounts.counts"
            :chartLabels="statusCounts.labels"
          ></BarChart>
        </div>
      </v-col>
      <v-col cols="12" md="4" class="pt-0">
        <div class="pl-4 pr-4" v-if="loaded && isVendor">
          <v-card-title class="pa-4">Rejected AR Invoices</v-card-title>
          <RejectedDocuments></RejectedDocuments>
        </div>
      </v-col>
      <v-col cols="12" md="4" class="pt-0">
        <div class="pl-4 pr-4" v-if="loaded && isOwner">
          <v-card-title class="pa-4">Division Orders Returned</v-card-title>
          <StackedBarChart
              :chartData="ownerDocumentSentCounts"
              :chartLabels="ownerDocumentSentLabels"
              :showLegend=false
          ></StackedBarChart>
        </div>
      </v-col>
      <v-col cols="12" md="4" class="pt-0">
        <div class="pl-4 pr-4" v-if="loaded && isOwner">
          <v-card-title class="pa-4">Owner Documents Received</v-card-title>
          <StackedBarChart
              :chartData="ownerDocumentReceivedCounts"
              :chartLabels="documentReceivedLabels"
              :showLegend=true
          ></StackedBarChart>
        </div>
      </v-col>
      <v-col cols="12" md="4" class="pt-0">
        <div class="pl-4 pr-4" v-if="loaded && isOwner">
          <v-card-title class="pa-4">Unsigned Division Orders</v-card-title>
          <NotReturnedDivisionOrders></NotReturnedDivisionOrders>
        </div>
      </v-col>
      <v-col cols="12" md="4" class="pt-0">
        <div class="pl-4 pr-4" v-if="loaded && isOperator">
          <v-card-title class="pa-4">Operator Documents Received</v-card-title>
          <StackedBarChart
              :chartData="operatorDocumentReceivedCounts"
              :chartLabels="documentReceivedLabels"
              :showLegend=true
          ></StackedBarChart>
        </div>
      </v-col>
    </v-row>
    <div class="pt-0 chat-container" v-if="loaded && messagingFlag">
      <ChatContainer />
    </div>
  </v-container>
</template>

<style>
  .chat-container {
    position: fixed; 
    bottom: 10px; 
    right: 10px; 
    width: 260px; 
    z-index: 2000; 
    pointer-events: auto; 
  }

</style>

<script>
import Auth0Mixin from "./Auth0Mixin";
import LineChart from "@/components/charts/LineChart.js";
import BarChart from "@/components/charts/BarChart.js";
import StackedBarChart from "@/components/charts/StackedBarChart";
import RejectedDocuments from "@/components/charts/RejectedDocuments";
import NotReturnedDivisionOrders from "@/components/charts/NotReturnedDivisionOrders";
import documentRouteService from "@/services/documentRouteService";
import documentStatusService from "@/services/documentStatusService";
import partyService from "@/services/partyService.js";
import documentRouteStatus from "@/constants/documentRouteStatus.js";
import {mapGetters} from "vuex";
import ChatContainer from "@/components/chat/ChatContainer.vue";

export default {
  name: "Home",
  mixins: [Auth0Mixin],
  components: {NotReturnedDivisionOrders, LineChart, RejectedDocuments, BarChart, StackedBarChart, ChatContainer },

  data() {
    return {
      showWelcomeDialog: false,
      loaded: false,
      isVendor: false,
      isOwner: false,
      isOperator: false,
      hasNoConnections: false,
      documentsSent: [],
      documentsByType: {
        counts: [],
        labels: [],
      },
      statusCounts: {
        counts: [],
        labels: [],
      },
      documentReceivedLabels: [],
      ownerDocumentReceivedCounts: [],
      operatorDocumentReceivedCounts: [],
      ownerDocumentSentCounts: [],
      ownerDocumentSentLabels: [],
    };
  },

  computed: {
    ...mapGetters("auth0Store", ["selectedCompanyId"]),
    ...mapGetters("featuresStore", ["messagingFlag"]),
  },
  methods: {
    async init() {
      const documentStats = await documentRouteService.getDocumentStats();
      const statusCounts = await documentStatusService.getCounts();
      const ownerDocReceivedCounts = await documentRouteService.getOwnerDocumentReceivedCountsPerDay();
      const ownerDocSentCounts = await documentRouteService.getOwnerDocumentSentCountsPerDay();

      let invoiceStatues = [documentRouteStatus.Sent, documentRouteStatus.Received, documentRouteStatus.Approved, documentRouteStatus.Paid, documentRouteStatus.Rejected, 
      documentRouteStatus.Resubmitted, documentRouteStatus.New];

      const filteredStatusCounts = statusCounts.filter(item => 
        invoiceStatues.includes(item.status.id)
      );

      this.documentsSent = [
        {
          label: "Invoices Sent",
          data: documentStats.documentsByMonth.map(p => ({
            x: p.month,
            y: p.count,
          })),
        },
      ];

      let counts = [],
        labels = [];
      documentStats.documentsByDocType.forEach(x => {
        counts.push(x.count);
        labels.push(x.documentType);
      });

      this.documentsByType.counts = [...counts];
      this.documentsByType.labels = [...labels];

      filteredStatusCounts.forEach(x => {
        this.statusCounts.counts.push(x.count);
        this.statusCounts.labels.push(x.status.name);
      });
      
      let divisionOrderReceivedCounts = [];
      let _1099ReceivedCounts =[];
      let runStatementReceivedCounts = [];
      let ownerStatementReceivedCounts = [];

      ownerDocReceivedCounts.forEach(x => {
        this.documentReceivedLabels.push(x.day);
        divisionOrderReceivedCounts.push(x.divisionOrderCount);
        _1099ReceivedCounts.push(x._1099Count);
        runStatementReceivedCounts.push(x.runStatementCount);
        ownerStatementReceivedCounts.push(x.ownerStatementCount);
      });
      
      this.ownerDocumentReceivedCounts = [
        {
          label: "Division Orders",
          data: divisionOrderReceivedCounts,
          backgroundColor: "#4facd1",
        },
        {
          label: "1099s",
          data: _1099ReceivedCounts,
          backgroundColor: "#886bd2",
        },
        {
          label: "Run Statements",
          data: runStatementReceivedCounts,
          backgroundColor: "#673AB7",
        },
        {
          label: "Owner Statements",
          data: ownerStatementReceivedCounts,
          backgroundColor: "#3f31e7",
        },
      ];

      this.operatorDocumentReceivedCounts = [
        {
          label: "Run Statements",
          data: runStatementReceivedCounts,
          backgroundColor: "#4facd1",
        },
        {
          label: "1099s",
          data: _1099ReceivedCounts,
          backgroundColor: "#673AB7",
        },
      ];

      let divisionOrderSentCounts = [];

      ownerDocSentCounts.forEach(x => {
        this.ownerDocumentSentLabels.push(x.day);
        divisionOrderSentCounts.push(x.divisionOrderCount);
      });

      this.ownerDocumentSentCounts = [
        {
          label: "Division Orders",
          data: divisionOrderSentCounts,
          backgroundColor: "#4facd1",
        },
      ];
      
      let partyTypes = await partyService.getPartyTypesByCompanyId(this.selectedCompanyId);
      if (partyTypes.length === 0){
        this.hasNoConnections = true;
      }else{
        partyTypes.forEach(pt => {
          if (pt.name === "Vendor"){
            this.isVendor = true;
          }
          if (pt.name === "Owner"){
            this.isOwner = true;
          }
          if (pt.name === "Operator"){
            this.isOperator = true;
          }
        });        
      }
      this.loaded = true;
    },

    paymentStatusClicked(index) {
      this.$router.push({
        name: "DocumentList",
        params: { documentTypeId: 93 },
        query: { documentRouteStatusId: index + 1 },
      });
    },
  },
};
</script>