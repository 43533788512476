export default {
    namespaced: true,
  
    state: {
      channels: [],
      users: [],
    },
  
    mutations: {
      updateChannels(state, channels) {
        state.channels = channels;

      },
      updateUsers(state, users) {
        state.users = users
      }
    },

    getters:{
        channels: state => state.channels,
        users: state => state.users, 
    },
    
  };
  