<template>
  <v-navigation-drawer fixed app clipped permanent :mini-variant.sync="isMobile" v-show="!loading">
    <v-list dense nav>
      <v-list-item-group no-action>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </template>
        <v-list-item v-for="item in userLinks" :key="item.title" :to="item.to" :data-cy="item.title">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.title }}
              <v-badge v-show="item.count" class="pl-2" color="blue" :content="item.count"></v-badge>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>

    <v-divider></v-divider>

    <v-list dense nav v-show="isAdmin">
      <v-list-item-group no-action>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </template>
        <v-list-item v-for="item in adminOperations" :key="item.title" :to="item.to">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.title }}
              <v-badge v-show="showCountBadge(item.title)" class="pl-2" color="grey" :content="getCount(item.title)"></v-badge>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import terminologyService from "@/services/terminologyService.js";
import terminology from "@/constants/terminology";
import { roles } from "@/services/constants";
import Auth0Mixin from "../views/Auth0Mixin";
import responsiveService from "@/services/responsiveService";
import {mapState} from "vuex";
import LegalDocumentType from "@/constants/legalDocumentType";

export default {
  name: "AppNavigation",
  mixins: [Auth0Mixin],
  data() {
    return {
      loading: true,
      userLinks: [],
      adminLinks: [
        { icon: "mdi-cog", title: "Logo", to: "/admin/logo" },
        { icon: "group_work", title: "Profiles", to: "/admin/orgunits" },
        { icon: "receipt_long", title: "W9", to: `/admin/legalDocuments/${LegalDocumentType.W9}` },
        {
          icon: "receipt_long",
          title: "Insurance Certificate",
          to: `/admin/legalDocuments/${LegalDocumentType.INSURANCE_CERTIFICATE}`,
        },
        { icon: "article", title: "ACH", to: "/admin/achs" },
        { icon: "person", title: "Users", to: "/admin/users" },
        { icon: "domain", title: "Connections", to: "/admin/parties" },
        { icon: "mdi-email-open-outline", title: "Invitations", to: "/admin/invitations", showInMobile: true, },
      ],
    };
  },
  methods: {
    showCountBadge(title) {
        return title == "Invitations" && this.pendingInvitations > 0;
    },

    getCount(title) {
        if (title == "Invitations") {
            return this.pendingInvitations;
        } else {
            return 0;
        }
    },

    populateUserLinks() {
      this.userLinks = [{ icon: "mdi-home", title: "Home", to: "/" }];

      // Add document types the user is allowed to access to the menu
      this.documentTypes?.forEach(dt => {
        this.userLinks.push({ icon: "mdi-file-document", title: `${dt.name}s`, to: `/documentlist/${dt.id}` });
      });
    },

    async init() {
      this.adminLinks[1].title = terminologyService.lookup(terminology.ORG_UNIT);
      this.loading = false;
      this.populateUserLinks();
    },
  },
  watch: {
    documentTypes() {
      this.populateUserLinks();
    },
  },

  computed: {
    ...mapState("documentTypeStore", ["documentTypes"]),
    ...mapState("invitationStore", ["pendingInvitations"]),
    isAdmin() {
      return this.$store.getters["auth0Store/userRoles"].includes(roles.Administrator);
    },
    isMobile() {
      return responsiveService.isMobile(this.$vuetify.breakpoint);
    },
    adminOperations() {
      return this.isMobile ? this.adminLinks.filter(x => x.showInMobile) : this.adminLinks;
    },
  },
};
</script>
