<template>
  <v-card v-if="show" class="chatbox">
    <v-card-title class="chatbox-header">
      <span>New Message</span>
      <v-btn icon @click="closeBox">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="chatbox-body">
      <v-select
        v-model="selected"
        :items="recipientList"
        item-text="name"
        item-value="chatId"
        chips
        multiple
        outlined
        label="Select recipients"
      >
        <template #selection="{ item }">
          <v-chip class="ma-2">{{ item.name }}</v-chip>
        </template>
        <template #item="{ item }">
          <div class="d-flex align-center">
            <v-avatar size="24" class="mr-2">{{ item.initials }}</v-avatar>
            <span>{{ item.name }}</span>
          </div>
        </template>
      </v-select>

      <v-textarea
        v-model="msg"
        outlined
        label="Write a message..."
        auto-grow
      ></v-textarea>

      <v-btn block color="primary" @click="sendMessage">
        Send
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "ChatBox",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      recipientList: [], // Fetch this from API or pass as prop
      selected: [],
      msg: "",
    };
  },
  methods: {
    async init() {
      this.recipientList = [
        { name: "Sigrid", initials: "S", chatId: "sigrid-134" },
        { name: "Leo", initials: "L", chatId: "leo-135" },
      ];
    },
    closeBox() {
      this.$emit("close");
    },
    sendMessage() {
      console.log("Message sent:", this.msg);
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped>
.chatbox {
  position: fixed;
  bottom: 10px;
  right: 320px;
  width: 300px;
  z-index: 2000;
}

.chatbox-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chatbox-body {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>
