import SendbirdChat from '@sendbird/chat'
import { GroupChannelModule } from '@sendbird/chat/groupChannel';
import appSettings from "@/appSettings";
import store from "@/store";

const channelHandlers = {
    onChannelsAdded: (context, channels) => {
        /*const updatedChannels = [...channels, ...stateRef.current.channels];
        updateState({ ...stateRef.current, channels: updatedChannels, applicationUsers: [] });*/
        console.log('you have been added to channels ...................', channels);
        channels.forEach(c => console.log(`you have been added to channel ${c.name}`));
    },
    onChannelsDeleted: (context, channels) => {
        /*const updatedChannels = stateRef.current.channels.filter((channel) => {
            return !channels.includes(channel.url);
        });
        updateState({ ...stateRef.current, channels: updatedChannels });*/
        console.log('deleted channel ...............', channels);
        channels.forEach(c => console.log(`channel ${c.name} has been deleted`));
    },
    onChannelsUpdated: (context, channels) => {
        // const updatedChannels = stateRef.current.channels.map((channel) => {
        //     const updatedChannel = channels.find(incomingChannel => incomingChannel.url === channel.url);
        //     if (updatedChannel) {
        //         return updatedChannel;
        //     } else {
        //         return channel;
        //     }
        // });

        // updateState({ ...stateRef.current, channels: updatedChannels });
        console.log('updated channel ..................', channels);
        channels.forEach(c => console.log(`channel ${c.name} was updated`));
    },
    onMessagesAdded: (context, channel, messages) => {
        console.log(`message received on the channel that you are part in`);
        console.log('messages are: ');
        messages.forEach(m => {
            console.log(m);
        });
    },
    onMessagesUpdated: (context, channel, messages) => {
        console.log(`message received on the channel that you are part in`);
        console.log('messages are: ');
        messages.forEach(m => {
            console.log(m);
        });
    }
};

export default {
    chat: null,
    company: null,
    async init(company) {
        this.company = company;
        this.chat = SendbirdChat.init({
            appId: appSettings.sendBirdAppId,
            modules: [
                new GroupChannelModule(),
            ],
        });

        try {
            let chatId = this.company.code + '-' + this.company.pakServiceId;
            var resultUser = await this.chat.connect(chatId);

            console.log(company, resultUser);

            const userUpdateParams = {
                nickname: company.name,
                userId: chatId
            };
            resultUser = await this.chat.updateCurrentUserInfo(userUpdateParams)
            console.log(resultUser);

        } catch (e) {
            console.log(e);
        }
    },
    async loadChannels() {
        try {
            const groupChannelFilter = new GroupChannelModule();
            groupChannelFilter.includeEmpty = true;
            var collection = this.chat.groupChannel.createGroupChannelCollection({
                filter: groupChannelFilter,
                order: GroupChannelModule.LATEST_LAST_MESSAGE
            });
            collection.setGroupChannelCollectionHandler(channelHandlers);
            let groupChannels = await collection.loadMore();
            console.log(groupChannels);
            store.commit("chatStore/updateChannels", groupChannels);

        } catch (e) {
            console.log(e);
        }
    },
    async createGroup(toCompany) {

        try {
            var channelParams = {
                name: this.company.code + '_' + toCompany.code,
                invitedUserIds: [toCompany.chatId],
                isDistinct: true,
                // data: {
                //   "initatingCompanyName": self.company.name
                // }
            }

            let channel = await this.chat.groupChannel.createChannel(channelParams);
            console.log(channel);

            return channel;
        } catch (e) {
            console.log(e);
        }
    },
    async sendMessage(company, msg) {

        try {
            var channel = await this.createGroup(company);
            var msgParams = { message: msg };
            console.log(channel, msg);

            channel.sendUserMessage(msgParams)
                .onSucceeded(rs => {
                    console.log('.... success sending message', rs);
                }).onFailed(err => {
                    console.log('.... error sending message', err);
                });
        } catch (e) {
            console.log(e);
        }
    }
}